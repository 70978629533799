import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { ToastContainer } from 'react-toastify'

import Airtable from 'airtable'
import NextNProgress from 'nextjs-progressbar'
import SwiperCore, { Autoplay, Mousewheel } from 'swiper'

import type { AppProps } from 'next/app'

import '../styles/global.scss'
import '@insquad/tools/build/index.css'

import {
  APP_AIRTABLE_API_KEY,
  HOTJAR_HJID,
  HOTJAR_HJSV,
} from 'src/utils/config'

SwiperCore.use([Mousewheel, Autoplay])
Airtable.configure({ apiKey: APP_AIRTABLE_API_KEY, noRetryIfRateLimited: true })

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (HOTJAR_HJID && HOTJAR_HJSV) {
      hotjar.initialize(parseInt(HOTJAR_HJID), parseInt(HOTJAR_HJSV))
    }
  }, [])

  return (
    <div>
      <NextNProgress
        startPosition={0.3}
        height={3}
        color="#373af5"
        showOnShallow={false}
      />

      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        draggable={false}
        position="top-right"
        closeButton={false}
        limit={3}
        hideProgressBar
        newestOnTop
      />

      <Component {...pageProps} />
    </div>
  )
}

export default MyApp
