function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`)
  }
  return variable
}

export const APP_MAIN_PAGE_PREVIEW_IMG_URL = requireEnv(
  process.env.NEXT_PUBLIC_MAIN_PAGE_PREVIEW_IMG_URL,
  'NEXT_PUBLIC_MAIN_PAGE_PREVIEW_IMG_URL'
)
export const APP_BACKEND_API = requireEnv(
  process.env.NEXT_PUBLIC_BACKEND_URI,
  'NEXT_PUBLIC_BACKEND_URI'
)
export const APP_SIGN_IN_REDIRECT_URL = requireEnv(
  process.env.NEXT_PUBLIC_SIGN_IN_REDIRECT_URL,
  'NEXT_PUBLIC_SIGN_IN_REDIRECT_URL'
)
export const APP_SIGN_UP_REDIRECT_URL = requireEnv(
  process.env.NEXT_PUBLIC_SIGN_UP_REDIRECT_URL,
  'NEXT_PUBLIC_SIGN_UP_REDIRECT_URL'
)
export const APP_SIGN_IN_CANDIDATE_REDIRECT_URL = requireEnv(
  process.env.NEXT_PUBLIC_SIGN_IN_CANDIDATE_REDIRECT_URL,
  'NEXT_PUBLIC_SIGN_IN_CANDIDATE_REDIRECT_URL'
)
export const APP_GTM_ID = requireEnv(
  process.env.NEXT_PUBLIC_GTM_ID,
  'NEXT_PUBLIC_GTM_ID'
)
export const APP_AMPLITUDE_KEY = requireEnv(
  process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
  'NEXT_PUBLIC_AMPLITUDE_KEY'
)
export const APP_CALL_PAGE_CALENDLY_URL = requireEnv(
  process.env.NEXT_PUBLIC_CALL_PAGE_CALENDLY_URL,
  'NEXT_PUBLIC_CALL_PAGE_CALENDLY_URL'
)
export const APP_STRAPI_URI =
  requireEnv(process.env.NEXT_PUBLIC_STRAPI_URI, 'NEXT_PUBLIC_STRAPI_URI') +
  '/api'
export const APP_HOST_NAME = requireEnv(
  process.env.NEXT_PUBLIC_HOST_NAME,
  'NEXT_PUBLIC_HOST_NAME'
)
export const APP_AIRTABLE_API_KEY = requireEnv(
  process.env.NEXT_PUBLIC_AIRTABLE_API_KEY,
  'NEXT_PUBLIC_AIRTABLE_API_KEY'
)
export const APP_AIRTABLE_COMPANY_BASE_ID = requireEnv(
  process.env.NEXT_PUBLIC_AIRTABLE_COMPANY_BASE_ID,
  'NEXT_PUBLIC_AIRTABLE_COMPANY_BASE_ID'
)
export const HOTJAR_HJID = requireEnv(
  process.env.NEXT_PUBLIC_HOTJAR_HJID,
  'NEXT_PUBLIC_HOTJAR_HJID'
)
export const HOTJAR_HJSV = requireEnv(
  process.env.NEXT_PUBLIC_HOTJAR_HJSV,
  'NEXT_PUBLIC_HOTJAR_HJSV'
)
export const SENTRY_DSN = requireEnv(
  process.env.NEXT_PUBLIC_SENTRY_DSN,
  'NEXT_PUBLIC_SENTRY_DSN'
)

export const isProd = () => process.env.NODE_ENV === 'production'
